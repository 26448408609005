import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import store from '../store';

const DAILY_USAGE_LIMIT = 3;

const initialState = {
  list : [],
  lastSave : "",
  dailyUsage : DAILY_USAGE_LIMIT,
};

// Slice
const backupSlice = createSlice({
  name: 'backup',
  initialState,
  reducers: {
    addBackupAction: (state, action) => {
      state.list.push(action.payload)
    },
    deleteBackupItemAction: (state, action) => {
      state.list.splice(action.payload, 1);
    },
    clearBackupAction : (state, action) => {
      state.list = []
    },
    backupAddCurrentGameAction : (state, action) => {
      state.list[state.list.length - 1].paragraphes = action.payload
    },
    backupSetParagrapheAction : (state, action) => {
      const {id, paragraphes} = action.payload;
      state.list[id].paragraphes.paragraph = paragraphes;
    },
    backupSetParagrapheSummaryAction : (state, action) => {
      const {id, summary} = action.payload;
      state.list[id].paragraphes.summary = summary;
    },
    setLastUseAction : (state, action) => {
      state.lastSave = Date.now();

      if(state.dailyUsage > 0)
        state.dailyUsage = state.dailyUsage - 1;
    },
    backResetDayAction : (state, action) => {
      state.dailyUsage = DAILY_USAGE_LIMIT;
    },
    
  }
});

// Reducers
export default backupSlice.reducer;

// Selectors
export const backupSelector = (state) => state.backup;

// Actions
const { addBackupAction, clearBackupAction, backupAddCurrentGameAction, backupSetParagrapheAction, setLastUseAction, backResetDayAction, deleteBackupItemAction } = backupSlice.actions;

// Thunks
export const addBackup = (datas) => (dispatch) => {
    dispatch(addBackupAction(datas));
};
export const clearBackup = () => (dispatch) => {
    dispatch(clearBackupAction());
};
export const backupAddCurrentGame = (paragraphes) => (dispatch) => {
    dispatch(backupAddCurrentGameAction(paragraphes));
};
export const backupSetParagraphe = (id, paragraphes) => (dispatch) => {
    dispatch(backupSetParagrapheAction({id, paragraphes}));
};
export const deleteBackupItem = (id) => (dispatch) => {
    dispatch(deleteBackupItemAction(id));
};

export const setLastUse = () => (dispatch) => {
    dispatch(setLastUseAction());
};

export const backResetDay = () => (dispatch) => {
    dispatch(backResetDayAction());
};

