import { combineReducers, configureStore, StateFromReducersMapObject } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunk from 'redux-thunk';
import logger from 'redux-logger'

import backupReducer from './backup';
import currentReducer from './current';

const rootReducer = combineReducers({
  backup: backupReducer,
  current: currentReducer
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ["current"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk, logger]
});

let persistor = persistStore(store)
/*
persistor.pause();
persistor.flush().then(() => {
  return persistor.purge();
});
*/

export default store;
export { persistor };

//export default store;

// https://codesandbox.io/s/i2g18?file=/src/components/Header/SwitchThemeMode/index.tsx:314-347