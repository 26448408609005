import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import store from '../store';

const initialState = {
  sentance : null,
  paragraphes : null
};

// Slice
const currentSlice = createSlice({
  name: 'current',
  initialState,
  reducers: {
    setSentanceAction: (state, action) => {
      state.sentance = action.payload
    },
    setCurrentGameContentAction: (state, action) => {
      state.paragraphes = action.payload
    },
  }
});

// Reducers
export default currentSlice.reducer;

// Selectors
export const currentSelector = (state) => state.current;

// Actions
const { setSentanceAction, setCurrentGameContentAction } = currentSlice.actions;

// Thunks
export const setSentance = (sentance) => (dispatch) => {
    dispatch(setSentanceAction(sentance));
};
export const setCurrentGameContent = (paragraphes) => (dispatch) => {
    dispatch(setCurrentGameContentAction(paragraphes));
};
